/* eslint-disable i18next/no-literal-string */
import Head from 'next/head';

export default function Home() {
    return (
        <>
            <Head>
                <title>Create Next App</title>
                <meta name="description" content="E-learning" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            Ahojky světe!
        </>
    );
}
